import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Error from "./Common/Error";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Cloud from "./pages/Services/Cloud";
import Hosting from "./pages/Services/Hosting";
import WebApp from "./pages/Services/WebApp";
import MobileApp from "./pages/Services/MobileApp";
import DigitalMarketing from "./pages/Services/DigitalMarketing";
import Consultancy from "./pages/Services/Consultancy";
import DataCenter from "./pages/Services/DataCenter";
import DataBox from "./pages/Products/DataBox";
import Network from "./pages/Products/Network";
import Wireless from "./pages/Products/Wireless";
import SoftwareLicense from "./pages/Solutions/SoftwareLicense";
import ECommerce from "./pages/Solutions/ECommerce";
import CMS from "./pages/Solutions/CMS";
import IOT from "./pages/Solutions/IOT";
import CRM from "./pages/Solutions/CRM";
import ERP from "./pages/Solutions/ERP";
import QMS from "./pages/Solutions/QMS";
import VAS from "./pages/Solutions/VAS";
import Training from "./pages/Training";
import Terms from "./pages/Terms";
import ScrollToTop from "./components/Common/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/training" element={<Training />} />
          <Route path="/terms" element={<Terms />} />

          {/* Services pages */}
          <Route path="/services/cloud" element={<Cloud />} />
          <Route path="/services/hosting" element={<Hosting />} />
          <Route path="/services/web-app" element={<WebApp />} />
          <Route path="/services/mobile-app" element={<MobileApp />} />
          <Route
            path="/services/digital-marketing"
            element={<DigitalMarketing />}
          />
          <Route path="/services/consultancy" element={<Consultancy />} />
          <Route path="/services/data-center" element={<DataCenter />} />

          {/* Products pages */}
          <Route path="/products/data-box" element={<DataBox />} />
          <Route path="/products/network" element={<Network />} />
          <Route path="/products/wireless" element={<Wireless />} />

          {/* Solutions pages */}
          <Route
            path="/solutions/software-license"
            element={<SoftwareLicense />}
          />
          <Route path="/solutions/e-commerce" element={<ECommerce />} />
          <Route path="/solutions/CMS" element={<CMS />} />
          <Route path="/solutions/IOT" element={<IOT />} />
          <Route path="/solutions/CRM" element={<CRM />} />
          <Route path="/solutions/ERP" element={<ERP />} />
          <Route path="/solutions/QMS" element={<QMS />} />
          <Route path="/solutions/VAS" element={<VAS />} />

          <Route path="*" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
