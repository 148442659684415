import React, { useState, useEffect } from "react";
import Menu from "../components/Common/Menu";
import Carousel from "../components/Home/Carousel";
import Offers from "../components/Home/Offers";
import Statistics from "../components/Home/Statistics";
import LogoSlider from "../components/Home/LogoSlider";
import Clients from "../components/Common/Clients";
import ContactUsNow from "../components/Common/ContactUsNow";
import Footer from "../components/Common/Footer";
import logo from "../images/GBT-logo.png";
import vector from "../images/vectors/home-vector.svg";
import { slides } from "../utils/slides";
import { offers } from "../utils/offers";
import { logos } from "../utils/logos";
import { partners } from "../utils/partners";

function Home() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 50) {
      // Adjust this value as needed
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {scrolled ? (
        <Menu />
      ) : (
        <div className="hidden top-8 absolute w-full xl:flex justify-center items-center">
          <Menu className="rounded-t-xl rounded-bl-xl" />
        </div>
      )}

      <div className="xl:hidden">
        <Menu />
      </div>

      {/* Sliders */}
      <Carousel slides={slides} />

      {/* About */}
      <div className="bg-white mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12">
        <div className="w-full flex flex-col md:flex-row items-center gap-8">
          <img
            className="w-[178.18px] h-[160px] brightness-90 hover:brightness-125"
            src={logo}
            alt="logo"
          />

          <div className="flex flex-col gap-4">
            <div className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent text-3xl lg:text-4xl font-extrabold drop-shadow-lightBlue uppercase">
              Who we are?
            </div>

            <div className="text-dark/50 text-lg text-justify font-normal leading-6">
              With over 5 years of experience and more than 15 Clients, GBT
              Solutions is one of the leading IT companies in Sudan. GBT is an
              end-to-end solutions and integrated service management
              organization, offering a comprehensive suite of services,
              solutions, and products to empower your business in today's
              digital age.{" "}
              <span className="font-bold">
                Here's a breakdown of what GBT can offer you:
              </span>
            </div>
          </div>
        </div>
      </div>

      <Offers
        className="bg-sky"
        title="GBT"
        subTitle="services"
        itemBgColor="lightGray"
        descriptionHeight="h-auto md:h-[84px]"
        description="GBT Services offers a comprehensive suite of solutions designed to empower your business at every stage of its digital journey."
        intro="Here's what we can do for you:"
        data={offers.services}
      />

      <Offers
        className="bg-white"
        title="GBT"
        subTitle="Solutions"
        itemBgColor="sky"
        descriptionHeight="h-auto md:h-[104px]"
        description="GBT Solutions offers a comprehensive suite of software solutions designed to address your specific needs and empower your business to achieve its full potential."
        intro="Here's a glimpse into the solutions we offer:"
        data={offers.solutions}
      />

      <Offers
        className="bg-sky"
        title="GBT"
        subTitle="Products"
        itemBgColor="lightGray"
        descriptionHeight="h-auto md:h-[110px]"
        description="GBT Products offer a range of high-performance solutions designed to empower your business with the essential tools to operate efficiently and securely."
        intro="Here's an overview of the products we offer:"
        data={offers.products}
      />

      <LogoSlider
        title="Our Partners"
        description="At GBT Solutions, we don't see limitations, we see potential. Our
          partners are as diverse as the tech landscape itself, spanning from
          budding startups to established giants. Each story, each challenge,
          fuels our passion to craft solutions that empower, regardless of your
          size, industry, or ambition."
        slides={partners}
      />

      <Statistics />

      <Clients
        title="Our Clients"
        description="At GBT Solutions, we don't see limitations, we see potential. Our
        customers are as diverse as the tech landscape itself, spanning from
        budding startups to established giants. Each story, each challenge,
        fuels our passion to craft solutions that empower, regardless of your
        size, industry, or ambition."
        logos={logos.customers}
      />

      <ContactUsNow
        title="Ready to dive in?"
        description="Don't hesitate to ask anything."
        vector={vector}
        subject="Home Page"
      />

      <Footer />
    </div>
  );
}
export default Home;
