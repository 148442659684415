import Ayman from "../images/teams/Ayman.png";
import Ginwan from "../images/teams/Ginwan.png";
import Houzifa from "../images/teams/Houzifa.png";
import Mahmoud from "../images/teams/Mahmoud.png";
import Mawada from "../images/teams/Mawada.png";
import Montasir from "../images/teams/Montasir.png";
import Mustafa from "../images/teams/Mustafa.png";
import MSalah from "../images/teams/MSalah.png";
import Jehan from "../images/teams/Jehan.png";
import Wafa from "../images/teams/Wafa.png";

export const teams = [
  {
    name: "Ayman Abdallah",
    role: "Mobile Developer",
    description: "Fast-learner and Curious",
    image: Ayman,
  },
  {
    name: "Ginwan Elgasim",
    role: "Front-end Developer",
    description: "Love Chocolate & Morning  Work Achievements",
    image: Ginwan,
  },
  {
    name: "Houzifa Abuzeaid",
    role: "Mobile Developer",
    description: "Flexible and Adaptable",
    image: Houzifa,
  },
  {
    name: "Jehan Hassan",
    role: "Software Engineer",
    description: "",
    image: Jehan,
  },
  {
    name: "Mahmoud Abdin",
    role: "Software Engineer",
    description: "Gamier and addictive to Milky tea",
    image: Mahmoud,
  },
  {
    name: "Mawada Azhari",
    role: "Software Developer",
    description: "funny and full of life",
    image: Mawada,
  },
  {
    name: "Mohamed Salah",
    role: "IT Support Engineer",
    description: "",
    image: MSalah,
  },

  {
    name: "Montasir Adam",
    role: "Data Analyst",
    description: "Problem Solver & Intelligent",
    image: Montasir,
  },
  {
    name: "Mustafa Salah",
    role: "UX Designer & Full-Stack Developer",
    description: "Creativity began at 13 age and keep going",
    image: Mustafa,
  },
  {
    name: "Wafaa Azhari",
    role: "Digital Marketing Specialist",
    description:
      "A passionate professional with a strong background in Information Technology",
    image: Wafa,
  },
  //   {
  //     name: "Abrar Mohammed",
  //     role: "Sales Executive",
  //     description: "",
  //     image: "",
  //   },
];
