import { teams } from "../../utils/teams";
const Teams: React.FC = () => {
  return (
    <div className="flex flex-col">
      <div className="hidden md:flex justify-center items-center relative bg-gradient-to-r from-secondary/75 to-primary/75">
        <div className="flex flex-row w-full">
          {teams.map((person) => (
            <div key={person.name} className="w-60 h-[232px]">
              <img
                className="w-full h-full opacity-40 object-cover object-top"
                src={person.image}
                alt=""
              />
            </div>
          ))}
        </div>

        <div className="w-full absolute bottom-0 py-12">
          <div className="mx-auto max-w-7xl flex flex-col">
            <h2 className="text-lightGray text-3xl lg:text-4xl font-bold uppercase drop-shadow-blue">
              Meet the Team
            </h2>

            <p className="mt-6 text-base lg:text-lg text-justify text-lightGray/75 font-semibold">
              Behind the code and circuits, lies the beating heart of GBT: our
              people. We're a tapestry of minds, each thread woven with diverse
              backgrounds, unique skillsets, and an infectious passion for what
              we do. From seasoned code architects to maverick data whisperers,
              we share a common language: the language of innovation.
            </p>
          </div>
        </div>
      </div>

      <div>
        <ul className="grid grid-cols-2 lg:grid-cols-5 gap-2">
          {teams.map((person) => (
            <li key={person.name}>
              <div className="relative w-full h-auto">
                <img
                  className="aspect-full w-full h-full object-cover opacity-80 hover:opacity-100"
                  src={person.image}
                  alt=""
                />

                <div className="absolute p-4 bottom-0">
                  <div className="text-white/75 text-base lg:text-xl font-bold">
                    {person.name}
                  </div>

                  <div className="text-indigo-50/75 text-sm lg:text-base font-medium">
                    {person.role}
                  </div>
                  {/* <div className="text-indigo-50/75 text-sm font-normal mt-2">
                    {person.description}
                  </div> */}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default Teams;
