//Services
import Cloud from "../images/gradientIcons/Cloud.svg";
import Globe from "../images/gradientIcons/Globe.svg";
import HardDrives from "../images/gradientIcons/HardDrives.svg";
import CodeBlock from "../images/gradientIcons/CodeBlock.svg";
import DeviceMobile from "../images/gradientIcons/DeviceMobile.svg";
import Speaker from "../images/gradientIcons/Speaker.svg";
import Lightbulb from "../images/gradientIcons/Lightbulb.svg";

// Solutions
import AppWindow from "../images/gradientIcons/AppWindow.svg";
import Database from "../images/gradientIcons/Database.svg";
import UserFocus from "../images/gradientIcons/UserFocus.svg";
import ShoppingCart from "../images/gradientIcons/ShoppingCartSimple.svg"; //********* No ShoppingCart.svg */
import NotePencil from "../images/gradientIcons/NotePencil.svg";
import UsersFour from "../images/gradientIcons/UsersFour.svg";
import Graph from "../images/gradientIcons/Graph.svg";
import CirclesThreePlus from "../images/gradientIcons/CirclesThreePlus.svg";

//Products
import WifiHigh from "../images/gradientIcons/WifiHigh.svg";
import Broadcast from "../images/gradientIcons/Broadcast.svg";

export const offers = {
  services: [
    {
      number: "1",
      icon: Cloud,
      link: "/services/cloud",
      title: "Cloud Services",
      description:
        "Eliminate the need for physical servers and experience scalable, on-demand computing power for your applications and data.",
    },
    {
      number: "2",
      icon: Globe,
      link: "/services/hosting",
      title: "Web Hosting & Domain Registering",
      description:
        "Secure your online presence with a custom domain name and reliable web hosting services.",
    },
    {
      number: "3",
      icon: HardDrives,
      link: "/services/data-center",
      title: "Data Center & Infrastructure",
      description:
        "Ensure the smooth operation of your IT infrastructure with reliable data center services and expert support.",
    },
    {
      number: "4",
      icon: CodeBlock,
      link: "/services/web-app",
      title: "Web Application Development",
      description:
        "Build dynamic and user-friendly web applications that streamline your business processes and enhance customer experience.",
    },

    {
      number: "5",
      icon: DeviceMobile,
      link: "/services/mobile-app",
      title: "Mobile Apps Development",
      description:
        "Reach your audience on the go with custom-designed mobile applications that cater to their needs and preferences.",
    },
    {
      number: "6",
      icon: Speaker,
      link: "/services/digital-marketing",
      title: "Digital Marketing Services",
      description:
        "Attract new customers and build brand awareness through targeted digital marketing campaigns.",
    },
    {
      number: "7",
      icon: Lightbulb,
      link: "/services/consultancy",
      title: "Consultation Services",
      description:
        "Benefit from the expertise of GBT consultants who can assess your specific needs and recommend the best technology solutions to achieve your goals.",
    },
  ],

  solutions: [
    {
      number: "1",
      icon: AppWindow,
      link: "/solutions/software-license",
      title: "Software Licensing Solutions",
      description:
        "Access the latest software applications with flexible licensing options tailored to your business needs.",
    },
    {
      number: "2",
      icon: Database,
      link: "/solutions/ERP",
      title: "ERP Solution",
      description:
        "Gain complete control over your business operations with an Enterprise Resource Planning (ERP) solution that integrates various functions.",
    },
    {
      number: "3",
      icon: UserFocus,
      link: "/solutions/CRM",
      title: "CRM Solution",
      description:
        "Streamline customer relationship management with a powerful CRM solution that centralizes customer data and interactions.",
    },
    {
      number: "4",
      icon: ShoppingCart,
      link: "/solutions/e-commerce",
      title: "E-commerce Solutions",
      description:
        "Establish a robust online presence for your business with comprehensive e-commerce solutions that facilitate secure and seamless online transactions.",
    },

    {
      number: "5",
      icon: NotePencil,
      link: "/solutions/CMS",
      title: "Content Management Solution",
      description:
        "Gain control over your website content with a user-friendly Content Management System (CMS) that allows for easy updates and management.",
    },
    {
      number: "6",
      icon: UsersFour,
      link: "/solutions/QMS",
      title: "Queue Management Solution",
      description:
        "Improve customer experience and optimize wait times with a queue management solution that provides visibility and organization.",
    },
    {
      number: "7",
      icon: Graph,
      link: "/solutions/IOT",
      title: "IoT Solutions",
      description:
        "Harness the power of the Internet of Things (IoT) to connect your devices, collect valuable data, and automate processes for increased efficiency.",
    },
    {
      number: "8",
      icon: CirclesThreePlus,
      link: "/solutions/VAS",
      title: "VAS Solution",
      description:
        "Explore a wide range of Value-Added Services (VAS) designed to enhance your existing solutions and cater to your specific business needs.",
    },
  ],

  products: [
    {
      number: "1",
      icon: HardDrives,
      link: "/products/data-box",
      title: "GBT DataBox",
      description:
        "Deploy a modular and containerized data center solution that offers exceptional scalability and rapid deployment for your IT infrastructure.        ",
    },
    {
      number: "2",
      icon: WifiHigh,
      link: "/products/network",
      title: "GBT Network Essentials",
      description:
        "Build a robust and reliable network foundation with high-performance network switches and secure Wi-Fi routers designed to meet the evolving needs of your business.",
    },
    {
      number: "3",
      icon: Broadcast,
      link: "/products/wireless",
      title: "GBT Wireless Solutions",
      description:
        "Establish reliable and high-speed wireless connectivity across long distances or connect multiple devices within a designated area with GBT's PTP and PTMP wireless solutions.",
    },
  ],
};
