import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { Contact } from "../../interfaces/Contact";
import { useTranslation } from "react-i18next";
import ButtonLoader from "../../Common/Loader/ButtonLoader";
import SuccessAlert from "../../components/Alerts/SuccessAlert";
import * as contactServices from "../../services/contact.service";

const ContactForm: React.FC<any> = ({
  id,
  pageSubject,
}: {
  id: string;
  pageSubject: string;
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const { t } = useTranslation();

  const initialValues: Contact = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  };

  const FormSchema = Yup.object().shape({
    firstName: Yup.string().required(t("required")),
    lastName: Yup.string().required(t("required")),
    email: Yup.string().required(t("required")),
    phoneNumber: Yup.string().required(t("required")),
    subject: Yup.string(),
    message: Yup.string().required(t("required")),
  });

  const handleForm = async (values: Contact, { resetForm }: any) => {
    console.log("🚀 ~ handleForm ~ values:", values);
    setSubmitting(true);
    contactServices
      .create(values)
      .then((response: any) => {
        if (response.status === 200) {
          console.log("response", response);
          setSubmitting(false);
          resetForm();
          setAlert(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAlert(false);
    }, 3000);
    return () => clearTimeout(timeout);
  }, [alert]);

  return (
    <div id={id} className="w-full bg-sky">
      {alert && (
        <div className="mb-3">
          <SuccessAlert />
        </div>
      )}

      <div className="mx-auto max-w-7xl px-8 sm:px-10 lg:px-12 py-12 cursor-default">
        <div className="bg-gradient-to-r from-primary to-dark bg-clip-text text-transparent text-3xl lg:text-4xl font-extrabold uppercase drop-shadow-lightBlue">
          Contact Us Form
        </div>
        <p className="text-justify text-dark/50 text-lg font-normal leading-6 my-4">
          Ready for better tech? Let's get started! Contact us by fill out the
          form.
        </p>

        <Formik
          initialValues={initialValues}
          validationSchema={FormSchema}
          onSubmit={handleForm}
        >
          {({ errors, touched }) => (
            <Form className="bg-white rounded-t-xl rounded-bl-xl p-4 lg:p-8 ">
              <div className="flex flex-col lg:flex-row gap-6 mb-6">
                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus-visible:outline-primary
                          ${
                            touched.firstName && errors.firstName
                              ? "border border-danger focus:border-danger focus:ring-danger"
                              : "border-none"
                          }`}
                    disabled={isSubmitting}
                  />
                  {touched.firstName && errors.firstName && (
                    <p className="text-danger">{errors.firstName}</p>
                  )}
                </div>
                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus-visible:outline-primary
                           ${
                             touched.lastName && errors.lastName
                               ? "border border-danger focus:border-danger focus:ring-danger"
                               : "border-none"
                           }`}
                    disabled={isSubmitting}
                  />
                  {touched.lastName && errors.lastName && (
                    <p className="text-danger">{errors.lastName}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col lg:flex-row gap-6 mb-6">
                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus-visible:outline-primary
                          ${
                            touched.email && errors.email
                              ? "border border-danger focus:border-danger focus:ring-danger"
                              : "border-none"
                          }`}
                    disabled={isSubmitting}
                  />
                  {touched.email && errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>

                <div className="w-full lg:w-1/2 flex flex-col">
                  <Field
                    type="text"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus-visible:outline-primary
                           ${
                             touched.phoneNumber && errors.phoneNumber
                               ? "border border-danger focus:border-danger focus:ring-danger"
                               : "border border-gray focus:border-gray focus:ring-gray"
                           }`}
                    disabled={isSubmitting}
                  />
                  {touched.phoneNumber && errors.phoneNumber && (
                    <p className="text-danger">{errors.phoneNumber}</p>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <Field
                  type="text"
                  name="subject"
                  value={pageSubject}
                  placeholder="Subject"
                  className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 capitalize focus-visible:outline-primary
                          ${
                            touched.subject && errors.subject
                              ? "border border-danger focus:border-danger focus:ring-danger"
                              : "border-none"
                          }`}
                  disabled={isSubmitting}
                />
                {touched.subject && errors.subject && (
                  <p className="text-danger">{errors.subject}</p>
                )}
              </div>

              <div className="mb-6">
                <Field
                  as="textarea"
                  rows={2}
                  type="text"
                  name="message"
                  placeholder="Message"
                  className={`w-full bg-sky rounded-t-xl rounded-bl-xl text-base text-dark placeholder:text-dark/50 font-normal placeholder:font-light px-4 py-5 focus-visible:outline-primary
                          ${
                            touched.message && errors.message
                              ? "border border-danger focus:border-danger focus:ring-danger"
                              : "border-none"
                          }`}
                  disabled={isSubmitting}
                />
                {touched.message && errors.message && (
                  <p className="text-danger">{errors.message}</p>
                )}
              </div>

              <button
                type="submit"
                className="w-full items-center justify-center rounded-t-xl rounded-bl-xl border border-none bg-gradient-to-r from-dark to-secondary p-4 text-white transition hover:bg-opacity-90"
              >
                {isSubmitting ? (
                  <span className="flex justify-center gap-2">
                    {t("Sending...")} <ButtonLoader />
                  </span>
                ) : (
                  <span className="text-lightGray text-base font-bold uppercase">
                    {t("send")}
                  </span>
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default ContactForm;
